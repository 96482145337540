import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { BRAND_ALIGNMENT_ITEMS, BRAND_PAGE_FILTER } from 'site-modules/shared/constants/brand-alignment';
import { getTitleWithNoOrphans } from 'site-modules/shared/utils/get-title-with-no-orphans';

import './brand-module.scss';

export function BrandModule({ pageFilter }) {
  return (
    <section className="brand-module d-lg-flex" data-tracking-parent="edm-entry-brand-module">
      <figure className="text-center d-flex justify-content-center align-self-center mb-1_5 mb-md-0" aria-hidden>
        <img
          className="brand-module-hero rounded"
          src={getStaticImageUrl('/img/homepage/brand-module/homepage-brand-module.webp')}
          alt=""
          style={{ maxHeight: '252px', maxWidth: '100%' }}
          loading="lazy"
          decoding="async"
        />
      </figure>
      <article className="ps-lg-2">
        <header className="mb-1_5 mb-md-0 text-center text-lg-start">
          <h2 className="text-capitalize heading-3 mb-0_5">{getTitleWithNoOrphans('Shoppers go to Edmunds first')}</h2>
          <div className="size-20 text-gray-darker">Resources for every car shopper - all in one place</div>
        </header>
        <ul className="list-unstyled px-1 px-lg-0 mb-0 mx-auto text-gray-darker">
          {BRAND_ALIGNMENT_ITEMS(pageFilter).map(({ label, description, icon }) => (
            <li key={label} className="pos-r ps-3_5 ps-lg-2_5 mt-0_75 mt-lg-1_5">
              <span
                className={classnames(icon, 'pos-a text-primary brand-module-icon')}
                style={{ left: '0' }}
                aria-hidden
              />
              <span className="fw-bold d-block d-lg-inline-block">{label}</span>
              <span className="d-none d-lg-inline">{`: `}</span>
              <span>{description}</span>
            </li>
          ))}
        </ul>
      </article>
    </section>
  );
}

BrandModule.propTypes = {
  pageFilter: PropTypes.oneOf(Object.values(BRAND_PAGE_FILTER)),
};

BrandModule.defaultProps = {
  pageFilter: undefined,
};
