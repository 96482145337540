import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { LeadFormMap } from 'client/tracking/maps/lead-form';
import { ToolMap } from './tool';
import { NavMap } from './nav';
import { NavMap as NavV2Map } from './nav-v2';

const map = {
  provide_zip_code: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
    TrackingConstant.ACTION_PROVIDE_ZIP_CODE
  ),

  view_search_result: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.VIEW_SEARCH_RESULT
  ),

  view_search_result_content: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_SEARCH_RESULT
  ),

  view_more_for_sale: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_MORE_FOR_SALE
  ),

  view_content: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CONTENT
  ),

  view_seo_internal_link: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SEO_INTERNAL_LINK
  ),

  view_content_search_result: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_SEARCH_RESULT
  ),

  view_content_summary: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_SUMMARY
  ),

  view_content_models: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_MODELS
  ),

  view_vehicle_ranking: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_VEHICLE_RANKING
  ),

  open_legacy_photoflipper: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PHOTO,
    TrackingConstant.OPEN_PHOTOFLIPPER
  ),

  open_photoflipper_360: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_PHOTO, 'open_photoflipper_360'),

  view_photo: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_PHOTO, TrackingConstant.ACTION_VIEW_PHOTO),

  view_pricing_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PRICING_DETAILS,
    TrackingConstant.VIEW_PRICING_DETAILS
  ),

  view_loan_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PRICING_DETAILS,
    TrackingConstant.VIEW_LOAN_DETAILS
  ),

  view_lease_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PRICING_DETAILS,
    TrackingConstant.VIEW_LEASE_DETAILS
  ),

  view_review: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_REVIEW, TrackingConstant.ACTION_VIEW_REVIEW),

  view_edmunds_review: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_REVIEW,
    TrackingConstant.VIEW_EDMUNDS_REVIEW
  ),

  view_consumer_review: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_REVIEW,
    TrackingConstant.VIEW_CONSUMER_REVIEW
  ),

  view_dealer_review: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_DEALER_REVIEW
  ),

  mmy_select: TrackingEvent.actionCompleted(TrackingConstant.ACTION_MMY_SELECT, TrackingConstant.ACTION_MMY_SELECT),

  mmy_select_submit: TrackingEvent.actionCompleted(TrackingConstant.ACTION_MMY_SELECT, TrackingConstant.SUBMIT_MMY),

  mmy_select_submit_make: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SUBMIT_MAKE
  ),

  mmy_select_submit_model: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SUBMIT_MODEL
  ),

  mmy_select_submit_make_model: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SUBMIT_MAKE_MODEL
  ),

  mmy_select_submit_mmyt: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SUBMIT_MMYT
  ),

  mmy_select_submit_style: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SUBMIT_MMY_STYLE
  ),

  seo_footer_mmy_submit_make: TrackingEvent.actionEnd(TrackingConstant.ACTION_MMY_SELECT, TrackingConstant.SUBMIT_MAKE),

  seo_footer_mmy_submit_model: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SUBMIT_MODEL
  ),

  seo_footer_mmy_submit: TrackingEvent.actionCompleted(TrackingConstant.ACTION_MMY_SELECT, TrackingConstant.SUBMIT_MMY),

  other_site_clickout: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.OTHER_SITE_CLICKOUT
  ),

  oem_site_clickout: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.OEM_SITE_CLICKOUT
  ),

  social_share_clickout: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.SOCIAL_CLICKOUT
  ),

  view_share_links: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_share_links'),

  close_adhesion: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.CLOSE_AD),

  type_select: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TYPE_SELECT, TrackingConstant.ACTION_TYPE_SELECT),

  extra_links_show_all: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_ALL
  ),

  view_vehicle_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_VEHICLE_DETAILS
  ),

  view_price_drop_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.PRICE_DROP
  ),

  view_viewed_vin_price_drop_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEWED_VIN_PRICE_DROP
  ),

  add_vehicle: TrackingEvent.actionCompleted(TrackingConstant.SAVE_VEHICLE, TrackingConstant.ADD_VEHICLE),

  website_clickout_custom(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_WEBSITE_CLICKOUT,
      TrackingConstant.OEM_SITE_CLICKOUT,
      options
    );
  },

  dealer_clickout_custom(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_WEBSITE_CLICKOUT,
      TrackingConstant.DEALER_SITE_CLICKOUT,
      options
    );
  },

  select_trim(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_CONFIGURE_VEHICLE,
      TrackingConstant.SELECT_TRIM,
      options
    );
  },

  link_select_tool_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.ACTION_TOOL_SELECT
  ),

  view_content_tool_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_TOOL_SELECT
  ),

  appraisal_link_tool_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.ACTION_APPRAISE_VEHICLE
  ),

  navigation_back: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RESET_NAVIGATION,
    TrackingConstant.NAVIGATION_BACK
  ),

  download_app_click: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, TrackingConstant.DOWNLOAD_APP),

  dashboard_send_feedback: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.OTHER_SITE_CLICKOUT
  ),

  configure_vehicle: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_CONFIGURE_VEHICLE,
    TrackingConstant.ACTION_CONFIGURE_VEHICLE
  ),

  dli_link: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.DLI_CLICK),

  view_faq_questions: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SUBACTION_FAQ_CONTENT
  ),

  close_banner: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.CLOSE_BANNER),

  view_search_result_online: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.VISIT_BUY_ONLINE_CHANNEL
  ),

  // Exit survey
  survey_trigger: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'survey_trigger'),
  select_exit: TrackingEvent.actionCompleted(TrackingConstant.ACTION_ENGAGE_SURVEY, 'select_exit_survey'),
  enter_exit_survey_yes_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_ENGAGE_SURVEY,
    'enter_exit_survey_yes_details'
  ),
  enter_exit_survey_no_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_ENGAGE_SURVEY,
    'enter_exit_survey_no_details'
  ),

  // Brand Association survey
  brand_assoc_survey: TrackingEvent.actionCompleted(TrackingConstant.ACTION_ENGAGE_SURVEY, 'brand_assoc_survey'),

  // News widget
  news_widget_frame_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CONTENT
  ),
  news_widget_see_all_news: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CONTENT
  ),

  // Ready for EV, EV Quiz
  treehouse_clickout: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'treehouse_clickout'),

  close_module: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SHOW_CONTENT,
    TrackingConstant.ACTION_CLOSE_MODULE
  ),

  // TODO: Review after core-6579
  // Privacy disclaimer
  close_privacy_disclaimer: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    'close_privacy_disclaimer'
  ),
};

// For unit testing purposes
export const CommonMap = {
  ...ToolMap,
  ...NavMap,
  ...NavV2Map,
  ...LeadFormMap,
  ...map,
};
