import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { bindToContent, CmsEntities } from 'client/data/models/cms';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { getRankingsContentPath } from 'site-modules/shared/utils/upper-funnel/content-path';
import { TypeRankingsEntities } from 'client/data/models/type-rankings-entities';
import { buildBestVehiclesByTypePath, HomePageModel } from 'client/data/models/homepage';
import { Link } from 'site-modules/shared/components/link/link';

import { VehicleCard } from './vehicle-card';

const TYPE_PLURAL = {
  suv: 'SUVs',
};

export function VehicleTypeModuleUI({ type, rankingContent, vehicleList, isMobile, wrapperClassName }) {
  if (!get(vehicleList, 'length')) return null;

  return (
    <section data-tracking-parent={`vehicle_type_module_${type}`} className={wrapperClassName}>
      <div className="mb-0_5">
        <span className="heading-3 me-1">{rankingContent && rankingContent.title}</span>
        {!isMobile && (
          <Link to={`/${type}/`} className="text-primary-darker" data-tracking-id="vehicle_type_module_cta">
            See all best {TYPE_PLURAL[type]}
          </Link>
        )}
      </div>
      <Row>
        {vehicleList.map(vehicle => (
          <Col xs={6} md={4} lg={2} className="mt-1" key={vehicle.id}>
            <VehicleCard type={type} vehicle={vehicle} />
          </Col>
        ))}
      </Row>
      {isMobile && (
        <Link
          to={`/${type}/`}
          className="text-primary-darker mt-2 d-inline-block"
          data-tracking-id="vehicle_type_module_cta"
        >
          See all best {TYPE_PLURAL[type]}
        </Link>
      )}
    </section>
  );
}

VehicleTypeModuleUI.propTypes = {
  type: PropTypes.string.isRequired,
  rankingContent: CmsEntities.Content,
  vehicleList: PropTypes.arrayOf(TypeRankingsEntities.VehicleRanking),
  isMobile: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

VehicleTypeModuleUI.defaultProps = {
  rankingContent: null,
  vehicleList: null,
  isMobile: false,
  wrapperClassName: null,
};

export const stateToPropsConfig = {
  rankingContent: bindToContent(({ type }) => getRankingsContentPath(type)),
  vehicleList: bindToPath(({ type }) => buildBestVehiclesByTypePath(type), HomePageModel),
};

export const VehicleTypeModule = connectToModel(VehicleTypeModuleUI, stateToPropsConfig);

VehicleTypeModule.propTypes = {
  type: PropTypes.string.isRequired,
};
