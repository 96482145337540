import React, { Fragment, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { get } from 'lodash';
import classnames from 'classnames';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

// Models
import { AdsModel } from 'client/data/models/ads';
import { CmsEntities, CmsModel, bindToContent } from 'client/data/models/cms';
import { HOME_PAGE_CONTENT_PATH } from 'site-modules/shared/constants/home-page';
import { buildBestVehiclesByTypePath, HomePageModel } from 'client/data/models/homepage';
import { HomeModulesModel } from 'client/data/models/home-modules';
import { ReturnDashboardModel } from 'client/data/models/return-dashboard';
import { InventoryModel } from 'client/data/models/inventory';
import { buildPubStateModelYearPath, VehicleModel } from 'client/data/models/vehicle';
import { FeatureFlagModel } from 'client/data/models/feature-flag';

// Actions
import { setCriticalCss } from 'client/actions/critical-css';

// Utils
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { pageDefinition } from 'site-modules/shared/pages/page';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { DEFAULT_CONTENT, parseContent, transform } from 'client/data/cms/content';
import { TrackingHandler } from 'client/tracking/handler';
import { getRankingsContentPath } from 'site-modules/shared/utils/upper-funnel/content-path';
import { getSimilarVinsPath, getVinWithFallbackPath } from 'site-modules/shared/utils/inventory/vdp-utils';

// Constants
import { TrackingMap } from 'client/tracking/maps/home-page';
import { CAR_NEWS_LANDING_CONTENT_PATH } from 'client/site-modules/shared/constants/editorial/editorial';
import { PUB_STATES } from 'client/constants/pub-states';
import { ANIMATED_LEADERBOARD_CMS_PATH } from 'site-modules/shared/components/sticky-banner/sticky-banner';

// Shared Components
import { SeoLinks } from 'site-modules/shared/components/seo-links/seo-links';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';
import { AppraiserSellModule } from 'site-modules/shared/components/appraiser-sell/appraiser-sell';
import { DealsModule } from 'site-modules/shared/components/deals-module/deals-module';
import { FeaturedSpotlightCarousel } from 'site-modules/shared/components/native-ad/featured-spotlight/featured-spotlight-carousel';
import { HashAnchor } from 'site-modules/shared/components/hash-anchor/hash-anchor';
import { NewsWidget } from 'client/site-modules/shared/components/news-widget/news-widget';
import { BrandModule } from 'site-modules/shared/components/brand-module/brand-module';
import { PartnerPromotions } from 'site-modules/shared/components/native-ad/partner-promotions/partner-promotions';
import { MinHeightWrapper } from 'site-modules/shared/components/min-height-wrapper/min-height-wrapper';
import { ORGANIZATION_JSON_LD_OBJECT } from 'site-modules/shared/pages/seo';

// Components
import { decoratorParams } from 'site-modules/shared/pages/decorators/decorator-params';
import { EdmundsDefaultDecorator } from 'site-modules/shared/pages/decorators/edmunds-default';
import { HomeAnnouncements } from 'site-modules/upper-funnel/components/announcements/announcements';
import { HomeContentGrid } from 'site-modules/upper-funnel/components/home-content-grid/home-content-grid';
import { HighImpact } from 'site-modules/upper-funnel/components/high-impact/high-impact';
import { VehicleShowcase } from 'site-modules/shared/components/upper-funnel/vehicle-showcase/vehicle-showcase';
import { VehicleTypeModule } from 'site-modules/upper-funnel/components/type/vehicle-type-module/vehicle-type-module';
import { BuyingGuideModule } from 'site-modules/upper-funnel/components/buying-guide-module/buying-guide-module';
import { InventoryModule } from 'site-modules/upper-funnel/components/inventory-module/inventory-module';
import { HomeHeroInventory } from 'site-modules/upper-funnel/components/home-hero-inventory/home-hero-inventory';
import { RecentlyViewed } from 'site-modules/upper-funnel/components/recently-viewed/recently-viewed';
import { ShopByType } from 'site-modules/upper-funnel/components/shop-by-type/shop-by-type';
import { HomeMarquee } from 'site-modules/shared/components/upper-funnel/home-marquee/home-marquee';
import { TopRatedPromoUnit } from 'site-modules/shared/components/top-rated-promo-unit/top-rated-promo-unit';

import './home.scss';

const UTMCNT_APPRAISE = 'appraise';
const UTMCNT_DEALS = 'deals';
const UTMCNT_SUV = 'suv';
const UTMCNT_BUYGUIDE = 'buyguide';
const UTMCNT_VIN = 'vin';
const UTMCNT_HIGHLIGHTS = 'highlights';
const UTMCNT_NAMES = [UTMCNT_APPRAISE, UTMCNT_DEALS, UTMCNT_SUV, UTMCNT_BUYGUIDE, UTMCNT_VIN];
const RE_UTMCNT = new RegExp(
  `(${UTMCNT_APPRAISE}|${UTMCNT_DEALS}|${UTMCNT_SUV}|${UTMCNT_BUYGUIDE}|${UTMCNT_VIN})(_)*(.+)*`
);

function renderModuleAnchor(anchorName) {
  return (
    <Fragment>
      <HashAnchor name={anchorName} />
      <ScrollElement id={anchorName} />
    </Fragment>
  );
}

function renderUtmModuleAnchor(module) {
  return renderModuleAnchor(`utm-${module}`);
}
function getIsHighlightsUtmcnt(utmcnt = '') {
  return utmcnt.includes(UTMCNT_HIGHLIGHTS);
}

function getUtmcnt(utmcnt = '') {
  const matches = utmcnt.match(RE_UTMCNT);
  const utmcntName = matches && UTMCNT_NAMES.includes(matches[1]) ? matches[1] : false;
  const utmcntValueMatch = matches && matches[3];
  const utmcntValue = utmcntValueMatch ? utmcntValueMatch.replace(`${UTMCNT_HIGHLIGHTS}_`, '') : utmcntValueMatch;
  return { utmcntName, utmcntValue };
}

function HomePageUI({ content, vehicleShowcaseContent, isMobile, featuredSpotlightAdSlides, utmcnt }) {
  const getUtmModule = useCallback(() => {
    const container = 'rounded bg-gray-lightest my-2 pb-1_5';
    const { utmcntName, utmcntValue } = getUtmcnt(utmcnt);

    if (utmcntName === UTMCNT_APPRAISE) {
      return (
        <Fragment>
          {renderUtmModuleAnchor(UTMCNT_APPRAISE)}
          <Container tag="section" className={classnames(container, 'px-0 px-md-1 pt-2 my-2')}>
            <AppraiserSellModule isAppraiserTabDefault canBeClosed={false} makeModelEnabled className="" />
          </Container>
        </Fragment>
      );
    } else if (utmcntName === UTMCNT_DEALS) {
      return (
        <Fragment>
          {renderUtmModuleAnchor(UTMCNT_DEALS)}
          <Container tag="section" className={classnames(container, 'px-0-5 px-md-1_5 pt-2')}>
            <DealsModule />
          </Container>
        </Fragment>
      );
    } else if (utmcntName === UTMCNT_SUV) {
      return (
        <Fragment>
          {renderUtmModuleAnchor(UTMCNT_SUV)}
          <Container tag="section">
            <VehicleTypeModule
              type={utmcnt}
              isMobile={isMobile}
              wrapperClassName={classnames(container, 'px-0-5 px-md-1_5 pt-1_5')}
            />
          </Container>
        </Fragment>
      );
    } else if (utmcntName === UTMCNT_BUYGUIDE) {
      return (
        <Fragment>
          {renderUtmModuleAnchor(UTMCNT_BUYGUIDE)}
          <Container tag="section" className={classnames(container, 'px-0-5 px-md-1_5 pt-2')}>
            <BuyingGuideModule content={content.child('buying-guide')} isMobile={isMobile} />
          </Container>
        </Fragment>
      );
    } else if (utmcntName === UTMCNT_VIN) {
      return (
        <Fragment>
          {renderUtmModuleAnchor(UTMCNT_VIN)}
          <InventoryModule vin={utmcntValue} isMobile={isMobile} isHighlightsUtmcnt={getIsHighlightsUtmcnt(utmcnt)} />
        </Fragment>
      );
    }
    return null;
  }, [content, isMobile, utmcnt]);

  useEffect(() => {
    TrackingHandler.useMap(TrackingMap);
  }, []);

  const announcement = content.child('announcement');
  const UtmModule = getUtmModule(utmcnt);

  return (
    <main id="main-content" className="home-page">
      <ErrorBoundary>
        <HomeHeroInventory trackingParent="edm-entry-home-page-hero" isMobile={isMobile} />
      </ErrorBoundary>
      <ErrorBoundary>
        <FeatureFlag name="announcements">
          <HomeAnnouncements content={announcement} />
        </FeatureFlag>
      </ErrorBoundary>
      <ErrorBoundary>{UtmModule || <RecentlyViewed isMobile={isMobile} />}</ErrorBoundary>
      <ErrorBoundary>
        <Container tag="section">
          <ShopByType className="mb-3_5 mt-2" isMobile={isMobile} />
          <HomeMarquee isTextOverlay={!isMobile} className="mb-2" />
          <TopRatedPromoUnit className="my-3" isMobile={isMobile} />
          <Row className="mb-2">
            <Col xs={12} lg={8} className="text-center">
              <PartnerPromotions cols={{ xs: 6, md: 4 }} showSeparators={false} />
            </Col>
            <Col xs={12} lg={4} className="pt-lg-3_5">
              <FeaturedSpotlightCarousel slides={featuredSpotlightAdSlides} className="mx-auto" />
            </Col>
          </Row>
        </Container>
      </ErrorBoundary>
      <ErrorBoundary>
        <Container tag="section">
          <BrandModule />
          <hr className="my-2" />
        </Container>
      </ErrorBoundary>
      <ErrorBoundary>
        <VehicleShowcase content={vehicleShowcaseContent} isMediumLayout={false} useContainer />
      </ErrorBoundary>
      <ErrorBoundary>
        <Container tag="section">
          {renderModuleAnchor('most-popular-vehicles')}
          <SeoLinks
            content={content.child('home-seo-footer')}
            className="most-popular-vehicles"
            btnClassName="ps-0_5"
            isHomepage
          />
          <hr className="mt-0_25 mb-2" />
        </Container>
      </ErrorBoundary>
      <ErrorBoundary>
        <Container className="mb-3 mb-md-0">
          {renderModuleAnchor('great-deal-with-edmunds')}
          <HomeContentGrid content={content.child('content-grid')} isMobile={isMobile} />
          <hr className="d-none d-md-block mt-1_5 mb-2" />
        </Container>
      </ErrorBoundary>
      {!isMobile && (
        <ErrorBoundary>
          <MinHeightWrapper componentType="HIGH_IMPACT">
            <HighImpact content={content.child('high-impact-carousel')} />
          </MinHeightWrapper>
        </ErrorBoundary>
      )}
      <ErrorBoundary>
        <Container>
          <hr className="mt-1_5 mb-2" />
          <NewsWidget className="news-widget-home" />
        </Container>
      </ErrorBoundary>
    </main>
  );
}

HomePageUI.propTypes = {
  content: CmsEntities.Content,
  vehicleShowcaseContent: CmsEntities.Content,
  isMobile: PropTypes.bool,
  featuredSpotlightAdSlides: PropTypes.arrayOf(PropTypes.shape({})),
  utmcnt: PropTypes.string,
};

HomePageUI.defaultProps = {
  content: DEFAULT_CONTENT,
  vehicleShowcaseContent: DEFAULT_CONTENT,
  isMobile: false,
  featuredSpotlightAdSlides: null,
  utmcnt: '',
};

export const mapStateToProps = state => ({
  isMobile: state.mobile,
  utmcnt: get(state, 'pageContext.ads.utmCnt.utmcnt'),
});

export const HomePage = connect(mapStateToProps)(
  connectToModel(HomePageUI, {
    content: bindToContent(HOME_PAGE_CONTENT_PATH),
    vehicleShowcaseContent: bindToPath('vehicleShowcaseContent', HomeModulesModel, transform),
    featuredSpotlightAdSlides: bindToPath('featuredSpotlightAd', AdsModel),
  })
);

const getPageOptions = params => ({
  name: get(params, 'pageName', 'home_page'),
  category: 'home_page',
  adsCustomTargeting: {
    sect8: get(params, 'ads.sect8'),
  },
  preload: store => {
    const state = store.getState();
    const { pageContext, mobile } = state;

    const preloader = new ModelPreloader(store);
    preloader.resolve(`content["${HOME_PAGE_CONTENT_PATH}"]`, CmsModel);
    preloader.resolve('homeHeroContent', HomeModulesModel);
    preloader.resolve('vehicleShowcaseContent', HomeModulesModel);
    preloader.resolve('content["editorial/includes/venom/home-page-pre-prod-list"]', CmsModel);
    preloader.resolve(`content["${CAR_NEWS_LANDING_CONTENT_PATH}"]`, CmsModel);
    preloader.resolve('featuredSpotlightAd', AdsModel);
    preloader.resolve('partnerPromotionsAd', AdsModel);
    preloader.resolve(`content["${ANIMATED_LEADERBOARD_CMS_PATH}"]`, CmsModel);

    const utmcntPageContext = get(pageContext, 'ads.utmCnt.utmcnt');
    const { utmcntName, utmcntValue } = getUtmcnt(utmcntPageContext);

    let utmcntCurrentName = utmcntName;
    if (utmcntCurrentName === UTMCNT_APPRAISE) {
      preloader.resolve(buildPubStateModelYearPath([PUB_STATES.NEW_USED, PUB_STATES.USED]), VehicleModel);
    } else if (utmcntCurrentName === UTMCNT_DEALS) {
      preloader.resolve(buildPubStateModelYearPath([PUB_STATES.NEW, PUB_STATES.NEW_USED]), VehicleModel);
    } else if (utmcntCurrentName === UTMCNT_SUV) {
      preloader.resolve(`content["${getRankingsContentPath(utmcntName)}"]`, CmsModel);
      preloader.resolve(buildBestVehiclesByTypePath(utmcntName), HomePageModel);
    } else if (utmcntCurrentName === UTMCNT_VIN) {
      preloader.resolve(getVinWithFallbackPath(utmcntValue), InventoryModel);
      preloader.resolve(getSimilarVinsPath(utmcntValue), InventoryModel);
    } else if (!utmcntCurrentName) {
      preloader.resolve('aggregatedMakeModels', ReturnDashboardModel);
    }

    return preloader.load().then(modelState => {
      const homeHeroContent = parseContent(modelState.get('homeHeroContent', HomeModulesModel)).children();
      let recentlyViewed = false;

      if (utmcntCurrentName === UTMCNT_SUV) {
        const vehiclesByType = modelState.get(buildBestVehiclesByTypePath(utmcntName), HomePageModel);
        if (!vehiclesByType || !vehiclesByType.length) {
          utmcntCurrentName = false;
        }
      } else if (utmcntCurrentName === UTMCNT_VIN) {
        const vinData = modelState.get(getVinWithFallbackPath(utmcntValue), InventoryModel);
        if (!vinData) {
          utmcntCurrentName = false;
        }
      } else if (!utmcntName) {
        const recentlyViewedData = modelState.get('aggregatedMakeModels', ReturnDashboardModel);
        recentlyViewed = !!(recentlyViewedData && recentlyViewedData.length);
      }

      const brandModule = !utmcntCurrentName && !recentlyViewed;

      store.dispatch(
        setCriticalCss({
          utmcnt: utmcntCurrentName,
          recentlyViewed,
          brandModule,
          hpMarqueeRow: Math.ceil(homeHeroContent.length / 2),
          topRatedPromo: !!modelState.get('top-rated-promo', FeatureFlagModel),
          criticalCssHeight: mobile ? 1000 : 1200,
        })
      );

      return preloader.load();
    });
  },
  seo: state => {
    const addOrganizationOnlyToAboutPage = get(state, 'featureFlags.enable-seot-3616-move-org-schema');
    const jsonld = addOrganizationOnlyToAboutPage ? [ORGANIZATION_JSON_LD_OBJECT] : [];
    return {
      canonical: 'https://www.edmunds.com',
      jsonld,
    };
  },
  component: HomePage,
  decorator: decoratorParams(EdmundsDefaultDecorator, { skipFloodlight: true, isHome: true, showSearchBar: false }),
});

export default params => pageDefinition(getPageOptions(params));
