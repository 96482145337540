import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Container from 'reactstrap/lib/Container';

import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { InventoryEntities, InventoryModel } from 'client/data/models/inventory';
import { HomeModulesModel } from 'client/data/models/home-modules';
import { getSimilarVinsPath, getVinWithFallbackPath } from 'site-modules/shared/utils/inventory/vdp-utils';
import { Carousel } from 'site-modules/shared/components/carousel/carousel';
import { breakpoints } from 'client/utils/bootstrap';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT, transform } from 'client/data/cms/content';
import { InventoryCardHighlights } from './inventory-card-highlights';
import { InventoryCard } from './inventory-card';
import './inventory-card.scss';

const RESPONSIVE_OPTIONS = [
  { breakpoint: breakpoints.sm, settings: { slidesToShow: 1.5, slidesToScroll: 1 } },
  { breakpoint: breakpoints.md, settings: { slidesToShow: 2.5, slidesToScroll: 2 } },
  { breakpoint: breakpoints.xl, settings: { slidesToShow: 3.5, slidesToScroll: 2 } },
];
const MOBILE_SLIDE_OPTIONS = { slidesToShow: 1.5, slidesToScroll: 1 };
const DESKTOP_SLIDE_OPTIONS = { slidesToShow: 4.5, slidesToScroll: 2 };

export function InventoryModuleUI({ vinData, similarVehicles, isMobile, isHighlightsUtmcnt, content }) {
  if (!vinData || !similarVehicles) {
    return null;
  }
  const getHasEligibleContent = () => {
    if (!isHighlightsUtmcnt) return false;
    const contentChildren = content.children();
    const eligibleChildren = contentChildren.filter(child => child.metadata('imageHighlights').value());
    return !!eligibleChildren.length;
  };
  const hasEligibleContent = getHasEligibleContent();
  const { make, model } = get(vinData, 'vehicleInfo.styleInfo', {});
  const vehicles = hasEligibleContent ? [vinData, vinData, ...similarVehicles] : [vinData, ...similarVehicles];
  const carouselOptions = isMobile ? MOBILE_SLIDE_OPTIONS : DESKTOP_SLIDE_OPTIONS;

  return (
    <Container className="px-0 my-2">
      <section data-tracking-parent="inventory_module" className="inventory-module">
        <div className="mb-0_5 ms-1_25 ms-md-0 heading-3 text-capitalize">
          {hasEligibleContent ? 'Vehicles Based On Your Search' : `Compare ${make} ${model} prices`}
        </div>

        <Carousel noAdsRefresh responsive={RESPONSIVE_OPTIONS} swipe infinite={false} {...carouselOptions}>
          {vehicles.map((vehicle, index) =>
            hasEligibleContent && index === 1 ? (
              <InventoryCardHighlights
                content={content}
                isHighlightsUtmcnt={isHighlightsUtmcnt}
                isMobile={isMobile}
                position={index + 1}
                key={`highlights`}
              />
            ) : (
              <InventoryCard vinData={vehicle} position={index + 1} key={vehicle.vin} />
            )
          )}
        </Carousel>
      </section>
    </Container>
  );
}

InventoryModuleUI.propTypes = {
  vinData: InventoryEntities.InventoryVin,
  similarVehicles: InventoryEntities.InventoriesVin,
  isMobile: PropTypes.bool,
  isHighlightsUtmcnt: PropTypes.bool,
  content: CmsEntities.Content,
};

InventoryModuleUI.defaultProps = {
  vinData: null,
  similarVehicles: null,
  isMobile: false,
  isHighlightsUtmcnt: false,
  content: DEFAULT_CONTENT,
};

export const InventoryModule = connectToModel(InventoryModuleUI, {
  vinData: bindToPath(({ vin }) => getVinWithFallbackPath(vin), InventoryModel),
  similarVehicles: bindToPath(({ vin }) => getSimilarVinsPath(vin), InventoryModel, (result, { vin }) =>
    get(result, 'results', []).filter(vehicle => vehicle.vin !== vin)
  ),
  content: bindToPath(({ isHighlightsUtmcnt }) => isHighlightsUtmcnt && 'homeHeroContent', HomeModulesModel, transform),
});

InventoryModule.propTypes = {
  vin: PropTypes.string.isRequired,
};
