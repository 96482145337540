import { createModelSegment } from 'client/data/luckdragon/segment';
import { get, set } from 'lodash';
import sha1 from 'hash.js/lib/hash/sha/1';
import { PageModel } from 'client/data/models/page';
import { EdmundsAPI, json } from 'client/data/api/api-client';
import { FeatureFlagModel } from 'client/data/models/feature-flag';
import { VisitorModel } from 'client/data/models/visitor';
import { HTTP_ACCEPTED } from 'client/utils/http-status';
import {
  DMA,
  LEASE_PAYMENT,
  LOAN_PAYMENT,
  ZIP_CODE,
} from 'site-modules/shared/constants/allowed-inventory-request-params';
import { withRetry } from 'client/utils/promise';
import { withMetrics } from 'client/data/api/api-metrics';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { gql } from '@apollo/client'; // eslint-disable-line
import { getCBPSortSRPFilter } from 'site-modules/shared/utils/car-buying/srp-filter';
import { getSearchResultsQuery } from 'site-modules/shared/utils/inventory/params-conversion';
import { addDaysToCurrentDate } from 'site-modules/shared/utils/date-utils';
import { PersonalizedSearchStorage } from 'site-modules/shared/utils/personalized-search/storage';
import { abortControllerManager } from 'site-modules/shared/utils/personalized-search/abort-controller-manager';
import { getTargetingDataFromInventory } from 'site-modules/shared/components/native-ad/utils/get-ad-targeting';
import { omitEmptyComponents } from 'site-modules/shared/utils/personalized-search/omit-empty-components';
import {
  isEditorialRatingPathExists,
  isRecommendedTrimPathExists,
} from 'site-modules/personalized-search/utils/check-paths-existence';
import { checkIsError } from 'site-modules/shared/utils/personalized-search/check-is-error';
import { logger } from 'client/utils/isomorphic-logger';

export const LLM_TIMEOUT = 10000;
export const LLM_GET_RETRIES_COUNT = 2;

const withRetry202Response = (promiseCall, retries, delayMS) => {
  // Rejects promise when 202 response received to let withRetry helper trigger retry.
  const reject202Response = response => {
    if (response.status === HTTP_ACCEPTED) {
      return Promise.reject({});
    }

    return response;
  };

  return withRetry((...params) => promiseCall(...params).then(reject202Response), retries, delayMS);
};

async function getSearchId(userSearch) {
  const idController = new AbortController();
  abortControllerManager.register(idController);

  const previousSearch = PersonalizedSearchStorage.getValueGivenExpirationDate('prevPersonalizedSearch');
  const request = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      question: userSearch,
      extra: {},
      ...(previousSearch ? { history: previousSearch } : {}),
    }),
    timeout: LLM_TIMEOUT,
  };

  const searchData = await EdmundsAPI.fetchJson(
    '/llm/v1/ask/pov/openai-4o-mini-0/?meta=true&cache=false&store_history=true',
    {
      ...request,
      signal: idController.signal,
    }
  );

  const searchId = get(searchData, 'id', '');
  const uuid = get(searchData, 'thread.uuid');

  return { searchId, uuid };
}

async function getSearchData(searchId, userSearch) {
  const searchDataController = new AbortController();
  abortControllerManager.register(searchDataController);

  const fetchWithoutBuiltinRetries = async (resourcePath, options) =>
    // We pass retries: 0 here for disabling default retry logic, provided by api-client, because of explicit usage of withRetry below
    EdmundsAPI.fetch(resourcePath, { ...options, retries: 0 });

  const fetchWithCustomRetry = withRetry202Response(fetchWithoutBuiltinRetries, LLM_GET_RETRIES_COUNT);
  const searchDataResponse = await fetchWithCustomRetry(`/llm/v1/get/${searchId}/?meta=true&input=true`, {
    timeout: LLM_TIMEOUT,
    signal: searchDataController.signal,
  });

  const data = await json(searchDataResponse);
  const { struct = {}, input = '' } = data;
  return { struct: omitEmptyComponents(struct), input, searchId, userSearch };
}

const getLlmAborted = () => 'llmAborted';
const getDataStatus = () => 'dataStatus';
const getUserSearch = () => 'userSearchQuery';
const getSearch = () => 'search';
const getSelectedFilters = () => 'selectedFilters';
const getInventoryData = () => 'inventoryData';
const getInventoryPriceIntervals = () => 'inventoryPriceIntervals';
const getInventoryPriceIntervalsQuery = () => 'inventoryPriceIntervalsQuery';
const getAdTargeting = () => 'adTargeting';
const getEditorialRatingPath = ({ make, model, submodel, year }) =>
  isEditorialRatingPathExists({ make, model, submodel, year })
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].editorialRating`
    : null;
const getEditorialTrims = ({ make, model, year }) =>
  isRecommendedTrimPathExists({ make, model, year })
    ? `makes["${make}"].models["${model}"].years["${year}"].editorialTrims`
    : null;
const getRecommendedTrimPath = ({ make, model, year }) =>
  isRecommendedTrimPathExists({ make, model, year })
    ? `makes["${make}"].models["${model}"].years["${year}"].recommendedTrim`
    : null;

export const PersonalizedSearchPaths = {
  getLlmAborted,
  getDataStatus,
  getUserSearch,
  getSearch,
  getSelectedFilters,
  getInventoryData,
  getEditorialRatingPath,
  getInventoryPriceIntervals,
  getInventoryPriceIntervalsQuery,
  getAdTargeting,
  getEditorialTrims,
  getRecommendedTrimPath,
};

export const LLM_DATA_STATUSES = {
  LOADING: 'loading',
  ERROR: 'error',
  EXPIRED: 'expired',
  SUCCESS: 'success',
};

export const PersonalizedSearchModel = createModelSegment('personalizedSearch', [
  /**
   * @see getLlmAborted
   */
  {
    path: 'llmAborted',
    async update(newValue) {
      if (newValue) {
        abortControllerManager.abort();
      }
      return newValue;
    },
  },

  /**
   * @see getDataStatus
   */
  { path: 'dataStatus' },

  /**
   * @see getUserSearch
   */
  { path: 'userSearchQuery' },

  /**
   * @see getSearch
   */
  {
    path: 'search',
    async resolve(match, context) {
      const [location, userSearchQuery] = await Promise.all([
        context.resolveValue('location', PageModel, false),
        // We resolve user search here, because luckdragon needs to add dependency.
        // Otherwise, userSearchQuery path changes will not call update method of this path
        context.resolveValue(getUserSearch()),
      ]);

      const locationSearchId = location.query?.key;

      if (!locationSearchId) {
        return null;
      }

      let dataStatus = LLM_DATA_STATUSES.LOADING;
      await context.updateValue('dataStatus', dataStatus);

      let searchData = { searchId: locationSearchId, userSearch: userSearchQuery?.userSearch };
      try {
        searchData = await getSearchData(locationSearchId, userSearchQuery?.userSearch);
        dataStatus = checkIsError(searchData) ? LLM_DATA_STATUSES.ERROR : LLM_DATA_STATUSES.SUCCESS;
      } catch (e) {
        dataStatus = LLM_DATA_STATUSES.EXPIRED;
      }

      await context.updateValue(getDataStatus(), dataStatus);

      const modifiedZip = get(searchData, 'struct.inventory.modifiedFacets.zip');

      if (modifiedZip) {
        try {
          await context.updateValue('location', { zipCode: modifiedZip }, VisitorModel);
        } catch (e) {
          logger('error', e);
        }
      }

      return searchData;
    },

    async update(value, match, context) {
      const [{ userSearch }, previousDataStatus] = await Promise.all([
        context.resolveValue(getUserSearch()),
        context.resolveValue(getDataStatus(), PersonalizedSearchModel, false),
      ]);

      if (!userSearch) {
        return null;
      }

      let prevSearchHistoryId;
      let searchData = {};
      let dataStatus = LLM_DATA_STATUSES.LOADING;

      await context.updateValue(getDataStatus(), dataStatus);
      await context.updateValue(getLlmAborted(), false);

      try {
        const { searchId, uuid: searchHistoryId } = await getSearchId(userSearch);

        prevSearchHistoryId = searchHistoryId;
        searchData = { searchId, userSearch };

        searchData = await getSearchData(searchId, userSearch);
        dataStatus = checkIsError(searchData) ? LLM_DATA_STATUSES.ERROR : LLM_DATA_STATUSES.SUCCESS;
      } catch (e) {
        dataStatus = LLM_DATA_STATUSES.ERROR;
      }

      abortControllerManager.reset();
      const llmAborted = await context.resolveValue(getLlmAborted(), PersonalizedSearchModel, false);

      if (llmAborted) {
        await context.updateValue(getLlmAborted(), false);
        await context.updateValue(getDataStatus(), previousDataStatus);
        return context.abort();
      }

      await context.updateValue(getSelectedFilters(), null);
      await context.updateValue(getDataStatus(), dataStatus);

      if (prevSearchHistoryId) {
        PersonalizedSearchStorage.setValueToLocalStorage(
          'prevPersonalizedSearch',
          JSON.stringify({ uuid: prevSearchHistoryId, expirationDate: addDaysToCurrentDate(1) })
        );
      }

      const modifiedZip = get(searchData, 'struct.inventory.modifiedFacets.zip');

      if (modifiedZip) {
        try {
          await context.updateValue('location', { zipCode: modifiedZip }, VisitorModel);
        } catch (e) {
          logger('error', e);
        }
      }

      return searchData;
    },
  },

  /**
   * @see getEditorialRatingPath
   * https://qa-21-www.edmunds.com/internal/editorial/graphiql/?query=%7B%0A%20%20editorialSubmodels(make%3A%22honda%22%2Cmodel%3A%22accord%22%2Cyear%3A%222024%22)%20%7B%0A%20%20%20%20submodel%0A%20%20%20%20rating_number%0A%20%20%20%20rating_enum%0A%20%20%7D%0A%7D
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].editorialRating',
    async resolve(match, context) {
      const response = await withMetrics(EdmundsGraphQLFederation, context).query(
        gql`
          query($make: String!, $model: String!, $submodel: String!, $year: String!) {
            editorialSubmodels(make: $make, model: $model, submodel: $submodel, year: $year) {
              submodel
              rating_number
              rating_enum
            }
          }
        `,
        {
          make: match.make,
          model: match.model,
          submodel: match.submodel,
          year: match.year,
        }
      );

      return get(response, `editorialSubmodels[0]`, null);
    },
  },

  // Inventory
  /**
   * @see getSelectedFilters
   */
  {
    path: 'selectedFilters',
  },

  /**
   * @see getInventoryData
   */
  {
    path: 'inventoryData',
    async resolve(match, context) {
      let filters = await context.resolveValue(getSelectedFilters());

      if (!filters) {
        return null;
      }

      const apiPath = '/purchasefunnel/v1/srp/inventory';
      const options = { timeout: 1000 };
      // skipCBPButton feature flag for SRP sorting --- start
      const skipCBPButton = await context.resolveValue('skipCBPButton', FeatureFlagModel);
      filters = getCBPSortSRPFilter({ filters, skipCBPButton });

      const { zipCode, dma, latitude, longitude, stateCode } = await context.resolveValue(
        'location',
        VisitorModel,
        false // we need prevent update location here, because we do update manually from the page component
      );

      const addLatLongParams = !!latitude && !!longitude;
      const latLongQueryParams = addLatLongParams ? { lat: latitude, lon: longitude } : {};
      const defaultRadiusParam = dma ? { [DMA]: dma } : {};

      const locationQueryParams = { [ZIP_CODE]: zipCode, ...defaultRadiusParam, ...latLongQueryParams };
      const pageSize = 21;

      const query = getSearchResultsQuery({
        ...locationQueryParams,
        ...filters,
        national: false,
        pageSize,
        stateCode,
      });

      const response = await withMetrics(EdmundsAPI, context).fetchJson(
        `${apiPath}?${query}&fetchSuggestedFacets=true`,
        options
      );
      const facets = get(response, 'facets', []).map(facet => {
        const { upperBound, values, type } = facet;
        if (upperBound === false && (type === LOAN_PAYMENT || type === LEASE_PAYMENT)) {
          const index = values.length - 1;
          return { ...facet, showAboveUpperBoundValue: true, values: [...values, { name: `${values[index].name}+` }] };
        }
        return facet;
      });

      set(response, 'facets', facets);

      return {
        ...response,
        hash: sha1()
          .update(query)
          .digest('hex'),
      };
    },
  },

  /**
   * Gets query params from purchase funnel attributes for aggregate API
   * @see getInventoryPriceIntervalsQuery
   */
  {
    path: 'inventoryPriceIntervalsQuery',
    async resolve(match, context) {
      const searchResults = await context.resolveValue(getInventoryData(), PersonalizedSearchModel, false);

      return searchResults?.attributes?.queryForByIntervalAggregation;
    },
  },

  /**
   * Get price intervals of existing search results
   * @see getInventoryPriceIntervals
   */
  {
    path: 'inventoryPriceIntervals',
    async resolve(match, context) {
      const query = await context.resolveValue(getInventoryPriceIntervalsQuery());

      if (!query) {
        return [];
      }

      const response = await withMetrics(EdmundsAPI, context).fetchJson(`/inventory/v5/aggregate?${query}`, context);

      return response?.buckets;
    },
  },

  /**
   * @see getAdTargeting
   */
  {
    path: 'adTargeting',
    async resolve(_, context) {
      const [inventoryData, selectedFilters] = await Promise.all([
        context.resolveValue(getInventoryData()),
        context.resolveValue(getSelectedFilters(), PersonalizedSearchModel, false),
      ]);

      return getTargetingDataFromInventory({ inventoryData, selectedFilters });
    },
  },
  /**
   * @see getEditorialTrims
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].editorialTrims',
    async resolve(match, context) {
      const response = await withMetrics(EdmundsGraphQLFederation, context).query(
        gql`
          query($make: String!, $model: String!, $year: String!) {
            editorialTrims(make: $make, model: $model, year: $year) {
              trim
              recommended
              displayName
              labels {
                label
                labelDisplay
                labelText
                authors {
                  name
                  authorTitle
                  image
                  bio
                }
                type
              }
            }
          }
        `,
        {
          make: match.make,
          model: match.model,
          year: match.year,
        }
      );

      return get(response, `editorialTrims`, []);
    },
  },

  /**
   * @see getRecommendedTrimPath
   * https://qa-21-www.edmunds.com/internal/editorial/graphiql/?query=%7B%0A%09editorialTrims(make%3A%22bmw%22%2C%20model%3A%22x7%22%2C%20year%3A%222025%22)%20%7B%0A%20%20%20%20trim%0A%20%20%20%20recommended%0A%20%20%7D%20%0A%7D
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].recommendedTrim',
    async resolve(match, context) {
      const { make, model, year } = match;
      const editorialTrims = await context.resolveValue(
        getEditorialTrims({ make, model, year }),
        PersonalizedSearchModel
      );

      return editorialTrims.find(trim => trim.recommended);
    },
  },
]);
