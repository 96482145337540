import React from 'react';
import PropTypes from 'prop-types';

import { BADGE, TypeRankingsEntities } from 'client/data/models/type-rankings-entities';
import { Link } from 'site-modules/shared/components/link/link';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { VehicleImage } from 'site-modules/shared/components/upper-funnel/vehicle-image/vehicle-image';
import { getAnchorName, getGenerationText } from 'site-modules/shared/utils/upper-funnel/type-rankings';
import { TopRatedBadgeUI } from 'site-modules/shared/components/top-rated-badge/top-rated-badge';

import './vehicle-card.scss';

export function VehicleCard({ type, vehicle }) {
  const { displayName } = vehicle;
  const { modelYear, slugRankedSubmodel, badges } = vehicle.segmentRatings[0];
  const { makeName, modelName, generations } = modelYear;

  const isTopRated = badges && badges.find(badge => badge === BADGE.TOP_RATED);
  const linkTo = `/${type}/#${getAnchorName(displayName)}`;
  const generationText = getGenerationText(generations);

  return (
    <div className="vehicle-type-module-card rounded bg-white d-flex flex-column h-100 pos-r">
      <AspectRatioContainer aspectRatio="3:2">
        <VehicleImage modelYear={modelYear} submodelSlug={slugRankedSubmodel} coreLink={linkTo} />
      </AspectRatioContainer>
      {isTopRated && <TopRatedBadgeUI isTopRated className="bg-white p-0_25" height={13} />}

      <div className="p-0_5 d-flex flex-column h-100">
        {generationText && <div className="label mb-0_5">{generationText}</div>}
        <div className="heading-6 mb-0_5">
          {makeName} {modelName}
        </div>
        <Link to={linkTo} className="medium text-primary-darker mt-auto" data-tracking-id="vehicle_type_module_cta">
          <span>{displayName}</span>
          <i className="icon-arrow-right4 size-10 ms-0_25" aria-hidden />
        </Link>
      </div>
    </div>
  );
}

VehicleCard.propTypes = {
  type: PropTypes.string.isRequired,
  vehicle: TypeRankingsEntities.VehicleRanking.isRequired,
};
