import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { ButtonGroup, TabPane, TabContent, Button, Container } from 'reactstrap';
import { Tab } from 'site-modules/shared/components/tabs-panel/tab';
import { MmyTabContent } from 'site-modules/shared/components/appraiser-sell/mmy-tab-content';
import { Storage } from 'site-modules/shared/utils/storage';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';

import './appraiser-sell.scss';

const APPRAISER_TAB_ID = 'tab-APPRAISER';
const SELL_TAB_ID = 'tab-SELL';
export const LOCAL_STORAGE_KEY = 'APPRAISER_SELL_MODULE_CLOSED';

const APPRAISER_TAB = {
  id: APPRAISER_TAB_ID,
  label: 'Appraise My Car',
  TabComponent: MmyTabContent,
  header: 'Find out what your car is really worth',
  subheader: 'Get a free Edmunds appraisal report for your car in minutes',
  badge: 'Edmunds Recommends',
};
const SELL_TAB = {
  id: SELL_TAB_ID,
  label: 'Sell My Car',
  TabComponent: MmyTabContent,
  header: 'Sell your extra car online today',
  subheader: 'Ready to sell? Get a no-obligation offer for your car in minutes',
  badge: 'New from Edmunds',
};
const TABS = {
  CHAL1: [APPRAISER_TAB, SELL_TAB],
  CHAL2: [SELL_TAB, APPRAISER_TAB],
};
const TABS_DATA = {
  [APPRAISER_TAB_ID]: APPRAISER_TAB,
  [SELL_TAB_ID]: SELL_TAB,
};

const CREATIVE_ID = 'appraise_sell_vehicle_entry';

export class AppraiserSellUI extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    isAppraiserTabDefault: PropTypes.bool,
    canBeClosed: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    className: PropTypes.string,
    makeModelEnabled: PropTypes.bool,
    ctaText: PropTypes.string,
    hideBadge: PropTypes.bool,
    header: PropTypes.string,
    headerClassName: PropTypes.string,
    preselectedMMY: PropTypes.shape({}),
  };

  static defaultProps = {
    isMobile: false,
    isAppraiserTabDefault: false,
    canBeClosed: true,
    className: 'shadow py-2',
    isEmbedded: false,
    makeModelEnabled: false,
    ctaText: undefined,
    hideBadge: false,
    header: null,
    headerClassName: 'heading-4',
    preselectedMMY: {},
  };

  constructor(props) {
    super(props);
    const { isAppraiserTabDefault } = this.props;
    this.state = {
      activeTabId: isAppraiserTabDefault ? APPRAISER_TAB_ID : SELL_TAB_ID,
      tabs: isAppraiserTabDefault ? TABS.CHAL1 : TABS.CHAL2,
      isModuleClosed: false,
    };
  }

  componentDidMount() {
    this.storage = new Storage('localStorage');
    this.checkModuleClosed();
  }

  /**
   * Toggles selected tab.
   * @param {string} tabId
   */
  setActiveTab = tabId =>
    this.setState(
      {
        activeTabId: tabId,
      },
      () => {
        const { activeTabId } = this.state;
        const activeTab = activeTabId === APPRAISER_TAB_ID ? APPRAISER_TAB : SELL_TAB;
        EventToolbox.fireTrackAction({
          event_type: TrackingConstant.EVENT_TYPE_ACTION_START,
          event_data: {
            action_name: TrackingConstant.ACTION_TOOL_SELECT,
            action_category: TrackingConstant.USER_ACTION_CATEGORY,
            action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
            subaction_name: TrackingConstant.TAB_SELECT,
            creative_id: CREATIVE_ID,
            value: activeTab.label,
          },
        });
      }
    );

  storage = null;

  /**
   * Sets module visibility status to state
   */
  checkModuleClosed = () => {
    const { canBeClosed } = this.props;
    const localStorageData = this.storage.get(LOCAL_STORAGE_KEY);
    const isModuleClosed = canBeClosed && !!localStorageData;

    this.setState(() => ({
      isModuleClosed,
    }));
  };

  /**
   * Hides module and set flag to storage
   */
  closeModule = () => {
    this.storage.set(LOCAL_STORAGE_KEY, true);
    this.setState({ isModuleClosed: true });
  };

  renderContainer = () => {
    const {
      isMobile,
      canBeClosed,
      isEmbedded,
      makeModelEnabled,
      ctaText,
      hideBadge,
      header,
      headerClassName,
      preselectedMMY,
    } = this.props;
    const { tabs, activeTabId } = this.state;

    return (
      <Container>
        <div
          id="appraiser-sell-tabs-container"
          className={classnames('appraiser-sell-tabs-container mb-0_5 pt-2_5 pb-0 pos-r bg-white', {
            'rounded px-1_5': !isEmbedded,
            'border-0': isEmbedded,
          })}
          data-tracking-parent={'appraise_sell_vehicle_entry'}
          data-tracking-value={'appraise-sell'}
        >
          {!hideBadge && (
            <div className="appraiser-sell-badge text-white bg-success size-12 fw-bold px-0_5 py-0_25">
              <i className="icon-fire me-0_5" aria-hidden />
              {TABS_DATA[activeTabId].badge}
            </div>
          )}
          <div
            id="appraiser-sell-tabs-container-label"
            className={classnames(headerClassName, 'mb-1_5 text-gray-darker')}
          >
            {header || TABS_DATA[activeTabId].header}
          </div>
          <div className="d-flex mb-1">
            <ButtonGroup size="md" role="tablist">
              {tabs.map(({ label, id }) => (
                <Tab
                  key={id}
                  label={label}
                  tabId={id}
                  isActive={id === activeTabId}
                  onClick={this.setActiveTab}
                  className="px-0 pt-0 pb-0_5 me-2"
                />
              ))}
            </ButtonGroup>
          </div>
          <TabContent activeTab={activeTabId}>
            {tabs.map(({ id, TabComponent, subheader }) => (
              <TabPane tabId={id} key={id} data-test={id}>
                {subheader && <p className="display-lg mb-1 mb-md-2">{subheader}</p>}
                <TabComponent
                  isMobile={isMobile}
                  isAppraiserPath={activeTabId === APPRAISER_TAB_ID}
                  makeModelEnabled={makeModelEnabled}
                  isEmbedded={isEmbedded}
                  ctaText={ctaText}
                  preselectedMMY={preselectedMMY}
                  tabId={id}
                  isTabActive={activeTabId === id}
                />
              </TabPane>
            ))}
          </TabContent>
          {canBeClosed && (
            <Button
              className="close-button p-0 text-gray-darker size-16"
              aria-label="Close"
              onClick={this.closeModule}
              data-tracking-id="appraiser_close_module"
            >
              <i className="icon-cross2" aria-hidden />
            </Button>
          )}
        </div>
      </Container>
    );
  };

  render() {
    const { className } = this.props;
    const { isModuleClosed } = this.state;

    if (isModuleClosed) return null;

    return (
      <aside className={classnames('appraiser-sell', className)} aria-labelledby="appraiser-sell-tabs-container-label">
        {this.renderContainer()}
      </aside>
    );
  }
}

export const mapStateToProps = state => ({
  isMobile: get(state, 'mobile'),
});

export const AppraiserSellModule = connect(mapStateToProps)(AppraiserSellUI);
