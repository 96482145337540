import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { Disclaimer } from 'site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';
import { getImageUrl, SOURCES } from 'client/utils/image-helpers';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { AdLabel } from 'site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import './high-impact-content.scss';

export function HighImpactContentSlide({ slideContent, position, isActive, slideId }) {
  const focusRef = useRef();
  const [prevActive, setPrevActive] = useState(isActive);

  useEffect(() => {
    if (isActive && isActive !== prevActive) {
      focusRef.current.focus();
    }
    setPrevActive(isActive);
  }, [isActive, prevActive]);

  const { image, url, urlText, urlTargetNew, verbiage, subtitle, oemLink, adLabel, disclaimer } = slideContent;

  const isTargetNew = urlTargetNew === 'true';
  const vehicleImage = getImageUrl(image, null, SOURCES.STATIC);
  return (
    <div
      className="pb-3 high-impact-content-slide text-white w-100 mx-0 mx-lg-auto pos-r"
      style={{ backgroundImage: `url("${vehicleImage}")` }}
      hidden={!isActive}
      role="tabpanel"
      tabIndex={-1}
      aria-labelledby={`tab-${slideId}`}
      id={`tabpanel-${slideId}`}
      ref={focusRef}
    >
      <div className="high-impact-content-slide-text mt-3 px-2">
        {adLabel && <AdLabel />}
        <ContentFragment classes="heading-3 text-white mt-1_25" componentToUse="h2">
          {verbiage}
        </ContentFragment>
        <hr className="w-25 mx-0" />
        {subtitle && <ContentFragment>{subtitle}</ContentFragment>}
        <div className={classNames('pt-0_75', { 'pb-0': disclaimer, 'pb-1': !disclaimer })}>
          <Button
            outline
            className="high-impact-cta text-capitalize px-1 py-0_5"
            href={encodeURI(url)}
            target={isTargetNew ? '_blank' : '_self'}
            rel="nofollow"
            data-tracking-id={isTargetNew ? 'high_impact_click_out' : 'high_impact_click_in'}
            data-tracking-value={`main_${urlText}`}
            data-tracking-position={position}
            aria-label={`${urlText} about ${verbiage}`}
          >
            <div className="heading-5 fw-normal high-impact-cta-text">
              {urlText}
              {isTargetNew && <i className="icon-open_in_new ps-0_25" aria-hidden />}
            </div>
          </Button>
          <span className="heading-7 text-white fw-normal ps-1">{oemLink}</span>
        </div>
        {disclaimer && (
          <div
            className={classNames('disclaimer-wrapper text-start pb-1', {
              'ps-2': isTargetNew,
              'ps-1_5': !isTargetNew,
            })}
          >
            <Disclaimer
              linkDisclaimer="Disclaimer*"
              disclaimerText={disclaimer}
              contentClasses="legal pt-2"
              data-tracking-parent="edm-entry-home-high-impact"
              openTrackingId="high_impact_disclaimer_click"
              closeTrackingId="high_impact_disclaimer_close"
            />
          </div>
        )}
      </div>
    </div>
  );
}

HighImpactContentSlide.propTypes = {
  slideContent: PropTypes.shape({
    image: PropTypes.string,
    url: PropTypes.string,
    urlText: PropTypes.string,
    urlTargetNew: PropTypes.string,
    verbiage: PropTypes.string,
    subtitle: PropTypes.string,
    oemLink: PropTypes.string,
    adLabel: PropTypes.string,
  }).isRequired,
  position: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  slideId: PropTypes.string.isRequired,
};

HighImpactContentSlide.defaultProps = {
  position: undefined,
};

export function HighImpactContent({ slides, selectedSlideIndex }) {
  const renderedSlides = slides.map((slideItem, index) => {
    if (!slideItem) {
      return false;
    }
    return (
      <HighImpactContentSlide
        key={slideItem.id}
        slideContent={slideItem.getAllMetadata()}
        position={`${index + 1}`}
        isActive={selectedSlideIndex === index}
        slideId={slideItem.id}
      />
    );
  });

  return <Fragment>{renderedSlides}</Fragment>;
}

HighImpactContent.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSlideIndex: PropTypes.number,
};

HighImpactContent.defaultProps = {
  selectedSlideIndex: 0,
};
