import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Card from 'reactstrap/lib/Card';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { AdLabel } from 'client/site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { Disclaimer } from 'client/site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { fireFeaturedSpotlightAdLoadTracking } from 'client/engagement-handlers/site-served-ads-engagement-handler/site-served-ads-engagement-handler';
import { TrackingHandler } from 'client/tracking/handler';
import { getCreativeId } from 'client/engagement-handlers/helper';

import './featured-spotlight-ad.scss';

const adsToMap = new Set();

export function FeaturedSpotlightAd({ content, id, isMobile }) {
  const card = useRef();

  const {
    logo,
    headline,
    url,
    urlText,
    urlMobile,
    oemLink,
    urlTargetNew,
    image,
    contentLabel,
    adLabel,
    disclaimer,
  } = content;

  function handleClick() {
    const action =
      urlTargetNew === 'true'
        ? 'home_page_featured_spotlight_ad_click_out'
        : 'home_page_featured_spotlight_ad_click_in';

    TrackingHandler.fireEvent(
      action,
      {},
      {
        creative_id: getCreativeId(card.current),
        action_cause: 'button_click',
        value: urlText,
      }
    );
  }

  useEffect(() => {
    if (!adsToMap.has(id)) {
      adsToMap.add(id);
      fireFeaturedSpotlightAdLoadTracking(card.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="featured-spotlight pos-r mb-1 pt-0_75 pt-md-0_25 pt-lg-0_75 pb-0_5 pb-md-0_25 pb-lg-0_5 ps-0 pe-0_5 px-md-0_25 ps-lg-1 pe-lg-0_5"
      style={{ overflow: 'hidden' }}
      ref={card}
    >
      <Card className="ed-zdepth-2 w-100">
        <a
          href={encodeURI(isMobile ? urlMobile : url)}
          className="text-decoration-none mt-0_75 mt-md-0_5 mt-lg-0_75 ms-1 ms-md-0_75 ms-lg-1"
          target={urlTargetNew === 'true' ? '_blank' : ''}
          onClick={handleClick}
        >
          {adLabel ? (
            <AdLabel adLabel={adLabel} styles={{ top: '17px', right: '13px' }} />
          ) : (
            <div className="content-label text-center" style={{ fontSize: '11px' }}>
              {contentLabel}
            </div>
          )}
          <div className="logo-wrapper w-100">
            {logo && (
              <img
                className="oem-logo d-block"
                alt=""
                src={getStaticImageUrl(logo)}
                loading="lazy"
                style={{
                  maxHeight: '20px',
                  maxWidth: '92px',
                }}
              />
            )}
          </div>
          <div
            className="headline fw-medium size-16 text-gray-darker mt-0_5 mb-0 mb-md-0_25 mb-lg-0"
            style={{
              fontSize: '1rem',
            }}
          >
            {headline && <ContentFragment componentToUse="span">{headline}</ContentFragment>}
          </div>
          <div className="size-16 text-primary-darker fw-medium text-url">
            {urlText}
            {urlTargetNew === 'true' && <i className="icon-open_in_new ms-0_25 small" aria-hidden />}
          </div>
          {oemLink && <div className="text-primary-darker disclaimer mb-0_25">{oemLink}</div>}
          <div className="image-wrapper">
            {image && (
              <img
                className="jellybean d-block m-auto"
                alt=""
                src={getStaticImageUrl(image)}
                loading="lazy"
                decoding="async"
              />
            )}
          </div>
        </a>
        {disclaimer && (
          <div className="disclaimer-wrapper text-start ms-0_75 ms-md-0_5 ms-lg-0_75">
            <Disclaimer
              linkDisclaimer="Disclaimer*"
              disclaimerText={disclaimer}
              contentClasses="legal mt-0_75 mt-md-0 mt-lg-0_75 mx-0_5 mx-md-0 mx-lg-0_5 pt-2 text-gray-dark"
              translateY={150}
            />
          </div>
        )}
      </Card>
    </div>
  );
}

FeaturedSpotlightAd.propTypes = {
  content: PropTypes.shape({
    logo: PropTypes.string,
    headline: PropTypes.string,
    url: PropTypes.string,
    urlText: PropTypes.string,
    urlMobile: PropTypes.string,
    oemLink: PropTypes.string,
    urlTargetNew: PropTypes.string,
    image: PropTypes.string,
    contentLabel: PropTypes.string,
    adLabel: PropTypes.string,
    disclaimer: PropTypes.string,
  }).isRequired,
  isMobile: PropTypes.bool,
  id: PropTypes.string,
};

FeaturedSpotlightAd.defaultProps = {
  isMobile: false,
  id: '',
};
