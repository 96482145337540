import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { AuthenticatedModalMap } from 'client/tracking/maps/insider/authenticated-modal';
import { GlobalSearchMap } from 'client/tracking/maps/global-search';
import { LOADING_SCREEN_CREATIVE_ID } from 'site-modules/shared/components/personalized-search/personalized-search-loading-screen/personalized-search-loading-screen';

export const NavMap = {
  global_nav_homepage_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RESET_NAVIGATION,
    TrackingConstant.ACTION_RESET_NAVIGATION
  ),

  global_nav_select_new_car: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.NEW_CAR_SELECT
  ),

  global_nav_select_used_car: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.USED_CAR_SELECT
  ),

  global_nav_begin_sign_up: TrackingEvent.actionStart(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.BEGIN_SIGN_UP),

  global_nav_begin_sign_in: TrackingEvent.actionStart(TrackingConstant.ACTION_SIGN_IN, TrackingConstant.BEGIN_SIGN_IN),

  global_nav_sign_out: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SIGN_OUT,
    TrackingConstant.ACTION_SIGN_OUT
  ),

  global_nav_click_saved_inventory: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SEE_ALL_SAVED
  ),

  global_nav_click_saved_searches: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SEE_ALL_SAVED
  ),

  global_nav_click_trade_in_values: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_APPRAISAL_DETAILS
  ),

  global_nav_click_fav_models: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SEE_ALL_SAVED
  ),

  global_nav_click_account: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACCESS_MY_ACCOUNT
  ),

  global_nav_click_send_feedback: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SEND_FEEDBACK
  ),

  ...AuthenticatedModalMap,

  // TODO: Review during shop-3436-global-nav-ai-search cleanup
  ...GlobalSearchMap,
  drawer_close: TrackingEvent.actionCompleted(TrackingConstant.ACTION_RESET_NAVIGATION, TrackingConstant.CLOSE_DRAWER, {
    creative_id: LOADING_SCREEN_CREATIVE_ID,
  }),
};
