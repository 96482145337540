import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import Container from 'reactstrap/lib/Container';

import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { ReturnDashboardModel } from 'client/data/models/return-dashboard';
import { Link } from 'site-modules/shared/components/link/link';
import { srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';

import { CORE_SRP_URL_PATTERN } from 'site-modules/shared/constants/inventory/srp-url-patterns';

import './recently-viewed.scss';

const SCROLL_OFFSET = 180;

function RecentlyViewedUI({ aggregatedMakeModels, className, isMobile, isAds9843Enabled }) {
  const listRef = useRef();
  const [leftOverlay, setLeftOverlay] = useState(false);
  const [rightOverlay, setRightOverlay] = useState(false);

  const setOverlays = useCallback(() => {
    if (!listRef.current) {
      return;
    }
    const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
    const maxScrollLeft = scrollWidth - clientWidth;

    setLeftOverlay(scrollLeft > 0);
    setRightOverlay(scrollLeft < maxScrollLeft);
  }, []);

  const scrollHandler = useCallback(debounce(setOverlays, 100), [setOverlays]);

  const handleLeftBtnClick = useCallback(() => {
    const element = listRef.current;
    element.scrollLeft -= SCROLL_OFFSET;
  }, []);

  const handleRightBtnClick = useCallback(() => {
    const element = listRef.current;
    element.scrollLeft += SCROLL_OFFSET;
  }, []);

  useEffect(setOverlays, [setOverlays, aggregatedMakeModels]);

  if (aggregatedMakeModels && !aggregatedMakeModels.length) {
    return null;
  }

  return (
    <div
      data-tracking-parent="edm-entry-return-dashboard"
      className={classnames('recently-viewed pos-r', className, {
        medium: isMobile,
        'is-ads-9843-chal': isAds9843Enabled,
      })}
    >
      <Container
        className={classnames('d-flex', {
          'flex-column': isMobile,
          'justify-content-center align-items-center': !isMobile,
        })}
      >
        <div className="text-nowrap medium me-1" id="recently-viewed-list">
          Recently Viewed:
        </div>
        <div className="pos-r overflow-x-hidden">
          <div
            className={classnames('overlay pos-a top-0 left-0 text-start d-flex align-items-center left', {
              invisible: !leftOverlay,
            })}
          >
            <button
              type="button"
              className="scroll-btn d-flex pe-1 ps-0"
              aria-hidden
              aria-label="left scroll"
              tabIndex={-1}
              onClick={handleLeftBtnClick}
            >
              <i className="medium text-primary icon-arrow-left4" aria-hidden />
            </button>
          </div>
          <ul
            ref={listRef}
            onScroll={scrollHandler}
            className="d-flex viewed-list text-nowrap list-unstyled mb-0 py-0_25"
            aria-labelledby="recently-viewed-list"
          >
            {aggregatedMakeModels.map(({ year, makeNiceId, modelNiceId, makeName, modelName }) => (
              <li key={`rv-${year}-${makeName}-${modelName}`} className="d-flex">
                <Link
                  to={srpLinkBuilder({
                    make: makeNiceId,
                    model: modelNiceId,
                    year,
                    initialUrlPattern: CORE_SRP_URL_PATTERN,
                  })}
                  rel="nofollow"
                  data-tracking-id="return_dashboard_mmy_select"
                  className="pos-r d-flex align-items-center text-underline text-cool-gray-30 fw-normal medium"
                >
                  {`${year} ${makeName} ${modelName}`}
                </Link>
              </li>
            ))}
          </ul>
          <div
            className={classnames(
              'overlay pos-a top-0 right-0 text-end d-flex align-items-center justify-content-end right',
              {
                invisible: !rightOverlay,
              }
            )}
          >
            <button
              type="button"
              className="scroll-btn d-flex ps-1 pe-0"
              aria-hidden
              aria-label="right scroll"
              tabIndex={-1}
              onClick={handleRightBtnClick}
            >
              <i className="medium text-primary icon-arrow-right4" aria-hidden />
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
}

RecentlyViewedUI.propTypes = {
  aggregatedMakeModels: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number,
      makeNiceId: PropTypes.string,
      modelNiceId: PropTypes.string,
      makeName: PropTypes.string,
      modelName: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  isAds9843Enabled: PropTypes.bool,
};

RecentlyViewedUI.defaultProps = {
  aggregatedMakeModels: [],
  className: null,
  isMobile: false,
  isAds9843Enabled: false,
};

export const stateToPropsConfig = {
  aggregatedMakeModels: bindToPath('aggregatedMakeModels', ReturnDashboardModel),
};

export const RecentlyViewed = connectToModel(RecentlyViewedUI, stateToPropsConfig);
