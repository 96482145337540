import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { randomInt } from 'client/utils/seed-randomizers';

import './vehicle-showcase-animation.scss';

export function VehicleShowcaseAnimation({ hvsAnimationImagesArray, hvsVehicle, tabIndex, isLazyImages }) {
  const inputEl = useRef(null);
  const animClassName =
    hvsAnimationImagesArray && hvsAnimationImagesArray.length ? `animation-${hvsAnimationImagesArray.length}up` : '';
  const vehicleId = `hvs-toggle-${hvsVehicle.replace(/\s/g, '-')}-${randomInt()}`;
  function updateInput() {
    const el = inputEl.current;
    const checked = el.checked;
    el.checked = !checked;
    el.dataset.isinputchecked = !checked;
  }

  return (
    <AspectRatioContainer aspectRatio="16:9" className="w-100">
      <input
        type="checkbox"
        className="hvs-animation-toggle invisible"
        id={vehicleId}
        defaultChecked
        tabIndex={-1}
        ref={inputEl}
        data-testid="is-input-checked"
        data-isinputchecked
      />
      <AspectRatioContainer
        aspectRatio="54:25"
        className={classnames('hvs-animation-container m-0 w-100 d-block', animClassName)}
      >
        <label htmlFor={vehicleId} className="w-100 h-100 m-0 p-0">
          {hvsAnimationImagesArray.map((src, index) => (
            <img
              key={src}
              className={`w-100 rounded-8 img-${index}`}
              src={src}
              alt=""
              loading={isLazyImages ? 'lazy' : undefined}
              decoding="async"
            />
          ))}
        </label>
        <button className="pos-a me-0_25" onClick={updateInput} tabIndex={tabIndex}>
          play/pause
        </button>
      </AspectRatioContainer>
    </AspectRatioContainer>
  );
}

VehicleShowcaseAnimation.propTypes = {
  hvsAnimationImagesArray: PropTypes.arrayOf(PropTypes.string),
  hvsVehicle: PropTypes.string,
  tabIndex: PropTypes.number,
  isLazyImages: PropTypes.bool,
};

VehicleShowcaseAnimation.defaultProps = {
  hvsAnimationImagesArray: '',
  hvsVehicle: '',
  tabIndex: -1,
  isLazyImages: false,
};
